<template>
  <div class="contentWrapper">
    <h1>Cart</h1>
    <div
      class="continueShoppingWrapper"
      v-if="currentCart.current_cart.total_item_count === 0"
    >
      <h2>Your cart is empty.</h2>
      <router-link class="btn" to="/trackir5">Continue Shopping</router-link>
    </div>

    <div
      class="cartWrapper"
      v-if="currentCart.current_cart.total_item_count > 0"
    >
      <cart-item
        :itemData="item"
        :cartMode="true"
        v-for="item in currentCart.current_cart.items"
        :key="item.id"
      ></cart-item>
      <div class="priceRows">
        <div class="subtotalRow">
          <div class="spacer"></div>
          <div class="rowLabel">Subtotal:</div>
          <div class="rowPrice">${{ currencyFormat(cartSubtotal) }}</div>
        </div>
        <!--END subtotalRow-->

        <div
          class="discountRow"
          v-if="currentCart.current_cart.coupon_discount != 0"
        >
          <div class="spacer"></div>
          <div class="rowLabel">Discount:</div>
          <div class="rowPrice">
            -${{ currencyFormat(currentCart.current_cart.coupon_discount) }}
          </div>
        </div>
        <!--END subtotalRow-->

        <div class="totalRow">
          <div class="spacer"></div>
          <div class="rowLabel">Total:</div>
          <div class="rowPrice">${{ currencyFormat(cartTotal) }}</div>
        </div>
        <!--END totalRow-->
      </div>
      <!--END priceRows-->
    </div>
    <!--END cartWrapper-->
    <div class="ctaRow" v-if="currentCart.current_cart.total_item_count > 0">
      <router-link id="shoppingBtn" class="btn" to="/trackir5">Continue Shopping</router-link>
      <router-link id="checkoutBtn" class="btn" to="/checkout">Checkout</router-link>
    </div>
    <!--END ctaRow-->
  </div>
  <!--END contentWrapper-->
</template>

<script>
import { mapState } from "vuex";
import { SET_CHECKOUT_FIELD } from "../store/mutation-types";
import CartItem from "@/components/CartItem";
export default {
  name: "cart",

  computed: {
    ...mapState({
      currentCart: (state) => state.cart,
      currentCheckoutData: (state) => state.checkout.checkoutData,
      shippingRatesData: (state) => state.checkout.destinationDetails,
      totalsData: (state) => state.checkout.totals,
    }),
    cartSubtotal: function () {
      var runningSubtotal = 0;
      this.currentCart.current_cart.items.forEach((item) => {
        runningSubtotal += item.unit_price * item.quantity;
      });
      return runningSubtotal;
      //return this.currentCart.current_cart.total_price;
    },
    cartTotal: function () {
      return this.cartSubtotal - this.currentCart.current_cart.coupon_discount;
    },
  },
  components: {
    CartItem,
  },
  methods: {
    currencyFormat(num) {
      if (!num) {
        num = 0;
      }
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    checkoutChange: function (newValue) {
      var formData = {};
      formData.field = event.target.name;
      formData.value = newValue;

      this.$store.commit(SET_CHECKOUT_FIELD, formData);
      this.$store.dispatch("calcTotals");
    },
    validate: function (validProp, valid) {
      this[validProp] = valid;

      /* Display shipping if customer section is valid */
      if (
        this.firstNameValid &&
        this.lastNameValid &&
        this.emailValid &&
        this.phoneValid
      ) {
        this.displayShippingForm = true;
      }

      /* Getting shipping data when address is complete */
      if (
        this.street1Valid &&
        this.cityValid &&
        this.stateValid &&
        this.zipValid &&
        !this.shippingRatesDisplayed
      ) {
        this.loadingShippingRates = true;
        this.getShippingEstimate();
        this.shippingRatesDisplayed = true;
      }

      /* Display billing if shipping section is valid */
      if (
        this.street1Valid &&
        this.cityValid &&
        this.stateValid &&
        this.zipValid &&
        this.shippingRatesDisplayed
      ) {
        this.loadingShippingRate = false;
        this.displayBillingForm = true;
      }
      if (this.cardNumberValid) {
        this.validForm = true;
      } else {
        this.validForm = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.contentWrapper {
  text-align: center;
  padding-bottom: 50px;
}

.cartWrapper {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-bottom: 50px;
}

.btn {
  @include btn();
}
p {
  a {
    text-decoration: underline;

    &:hover {
      color: #ffffff;
    }
  }
}

.discountRow,
.subtotalRow,
.shippingRow,
.taxesRow,
.totalRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .spacer {
    width: 100%;
  }

  .rowLabel {
    white-space: nowrap;
    text-align: right;
    box-sizing: border-box;
  }
  .rowPrice {
    white-space: nowrap;
    padding-left: 20px;
    margin-right: 20px;
    min-width: 110px;
    text-align: right;
    box-sizing: border-box;
  }
}

.totalRow {
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  .rowLabel,
  .rowPrice {
    padding-top: 10px;
    border-top: 1px solid #ffffff;
  }
}
.priceRows {
  margin-top: 13px;
}
.ctaRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.orderBtn {
  margin-top: 30px;
  background-color: rgba(0, 0, 0, 0.1);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    background-color: #000000;
  }
}
.checkoutDetails {
  text-align: left;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  h1 {
    font-size: 18px;
    margin: 20px 0 0;
  }
  p {
    margin: 0;
  }
}

.error {
  border: 1px solid red;
}

label {
  position: absolute;
  top: 10px;
  left: 8px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s;
  pointer-events: none;
}
</style>